export const NAMES = {
	TROOPS: "TROOPS",
	JETS: "JETS",
	HELI: "HELI",
	TANKS: "TANKS",
	BMP_BTR: "BMP_BTR",
	ARTA: "ARTA",
	SHIPS: "SHIPS",
	AUTO: "AUTO",
	UAV: "UAV",
	PPO: "PPO"
};

export const TITLES = {
	TROOPS: "Особовий склад",
	JETS: "Літаки",
	HELI: "Гелікоптери",
	TANKS: "Танки",
	BMP_BTR: "БМП і БТР",
	ARTA: "Артилерія",
	SHIPS: "Кораблі",
	AUTO: "Авто техніка",
	UAV: "БПЛА",
	PPO: "Засоби ППО",
};

export const colors = {
	TROOPS: "rgb(245,7,57)",
	JETS: "rgb(59,7,245)",
	UAV: "rgb(2,91,184)",
	HELI: "rgb(7,134,245)",
	TANKS: "rgb(7,245,189)",
	BMP_BTR: "rgb(154,245,7)",
	ARTA: "rgb(245,221,7)",
	SHIPS: "rgb(245,82,7)",
	AUTO: "rgb(141,47,3)",
	PPO: "rgb(62,1,161)",
};

export const icons = {
	TROOPS: "soldier.png",
	JETS: "jet.png",
	UAV: "uav.png",
	HELI: "heli.png",
	TANKS: "tank.png",
	BMP_BTR: "btr.png",
	ARTA: "arta.png",
	SHIPS: "ship.png",
	AUTO: "car.png",
	PPO: "aaws.png"
};

export const badges = {
	JETS: {icon: "/img/badge/details.png", icon_active: "/img/badge/details_active.png", height: "1.6em"},
	HELI: {icon: "/img/badge/details.png", icon_active: "/img/badge/details_active.png", height: "1.6em"},
	UAV: {icon: "/img/badge/details.png", icon_active: "/img/badge/details_active.png", height: "1.6em"},
	SHIPS: {icon: "/img/badge/details.png", icon_active: "/img/badge/details_active.png", height: "1.6em"}
};

export const badges_url = {
	JETS: "/destroyed-jets",
	HELI: "/destroyed-heli",
	UAV: "/destroyed-uavs",
	SHIPS: "/destroyed-ships"
};

export const icons_height = {
	UAV: "1em",
	JETS: "2em"
};

export const costs = {
	TROOPS: 0,
	JETS: 25000000,
	HELI: 18000000,
	TANKS: 2486000,
	BMP_BTR: 581000,
	ARTA: 0,
	SHIPS: 0,
	AUTO: 170940,
	UAV: 80000,
	PPO: 0
};

export const estimates = {
	TROOPS: [900000, 800000, 700000, 600000,500000,450000,400000],
	JETS: [],
	HELI: [],
	TANKS: [10000, 9000, 8000, 7000, 6500, 6000],
	BMP_BTR: [21000, 20000, 19000, 18000, 17000, 16000, 15000, 14000, 13500, 13000, 12500, 12000],
	ARTA: [22000, 21000, 20000, 19000, 18000, 17000, 16000, 15000, 14000, 13000, 12000, 11000, 10000, 9000],
	SHIPS: [],
	AUTO: [],
	UAV: [],
	PPO: [1100,1000,900,800,700,600]
};

const getSpecific = (arr) => {
	return {
		cost: arr.reduce((acc, number) => {
			return acc + number;
		}, 0),
		amount: arr.length
	}
}

export const costs_specific = {
	SHIPS: getSpecific([750000000]),
	UAV: getSpecific([7000000])
};
