import {NAMES} from "./constants";

export const lossDataNewSet3 = {
    "Jan 01 2025": {
        [NAMES.TROOPS]: 790800,
        [NAMES.TANKS]: 9672,
        [NAMES.BMP_BTR]: 20043,
        [NAMES.ARTA]: 21532,
        [NAMES.PPO]: 1032,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 330,
        [NAMES.UAV]: 21131,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 32675
    },
    "Jan 02 2025": {
        [NAMES.TROOPS]: 792170,
        [NAMES.TANKS]: 9676,
        [NAMES.BMP_BTR]: 20056,
        [NAMES.ARTA]: 21552,
        [NAMES.PPO]: 1032,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 330,
        [NAMES.UAV]: 21200,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 32729
    },
    "Jan 03 2025": {
        [NAMES.TROOPS]: 793250,
        [NAMES.TANKS]: 9677,
        [NAMES.BMP_BTR]: 20070,
        [NAMES.ARTA]: 21555,
        [NAMES.PPO]: 1032,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 330,
        [NAMES.UAV]: 21249,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 32770
    },
    "Jan 04 2025": {
        [NAMES.TROOPS]: 794760,
        [NAMES.TANKS]: 9679,
        [NAMES.BMP_BTR]: 20093,
        [NAMES.ARTA]: 21578,
        [NAMES.PPO]: 1032,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 21356,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 32843
    },
    "Jan 05 2025": {
        [NAMES.TROOPS]: 796490,
        [NAMES.TANKS]: 9686,
        [NAMES.BMP_BTR]: 20119,
        [NAMES.ARTA]: 21603,
        [NAMES.PPO]: 1032,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 21447,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 32940
    },
    "Jan 06 2025": {
        [NAMES.TROOPS]: 798040,
        [NAMES.TANKS]: 9700,
        [NAMES.BMP_BTR]: 20164,
        [NAMES.ARTA]: 21665,
        [NAMES.PPO]: 1034,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 21625,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33056
    },
    "Jan 07 2025": {
        [NAMES.TROOPS]: 800010,
        [NAMES.TANKS]: 9710,
        [NAMES.BMP_BTR]: 20189,
        [NAMES.ARTA]: 21710,
        [NAMES.PPO]: 1038,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 21708,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33226
    },
    "Jan 08 2025": {
        [NAMES.TROOPS]: 801670,
        [NAMES.TANKS]: 9714,
        [NAMES.BMP_BTR]: 20205,
        [NAMES.ARTA]: 21729,
        [NAMES.PPO]: 1038,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 21727,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33307
    },
    "Jan 09 2025": {
        [NAMES.TROOPS]: 803100,
        [NAMES.TANKS]: 9731,
        [NAMES.BMP_BTR]: 20221,
        [NAMES.ARTA]: 21765,
        [NAMES.PPO]: 1038,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 21813,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33387
    },
    "Jan 10 2025": {
        [NAMES.TROOPS]: 804930,
        [NAMES.TANKS]: 9741,
        [NAMES.BMP_BTR]: 20253,
        [NAMES.ARTA]: 21789,
        [NAMES.PPO]: 1041,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 21884,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33473
    },
    "Jan 11 2025": {
        [NAMES.TROOPS]: 806500,
        [NAMES.TANKS]: 9751,
        [NAMES.BMP_BTR]: 20271,
        [NAMES.ARTA]: 21817,
        [NAMES.PPO]: 1042,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 21958,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33534
    },
    "Jan 12 2025": {
        [NAMES.TROOPS]: 808250,
        [NAMES.TANKS]: 9756,
        [NAMES.BMP_BTR]: 20289,
        [NAMES.ARTA]: 21839,
        [NAMES.PPO]: 1042,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22021,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33598
    },
    "Jan 13 2025": {
        [NAMES.TROOPS]: 809760,
        [NAMES.TANKS]: 9764,
        [NAMES.BMP_BTR]: 20315,
        [NAMES.ARTA]: 21876,
        [NAMES.PPO]: 1044,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22204,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33708
    },
    "Jan 14 2025": {
        [NAMES.TROOPS]: 811090,
        [NAMES.TANKS]: 9767,
        [NAMES.BMP_BTR]: 20326,
        [NAMES.ARTA]: 21923,
        [NAMES.PPO]: 1044,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22276,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33791
    },
    "Jan 15 2025": {
        [NAMES.TROOPS]: 812670,
        [NAMES.TANKS]: 9780,
        [NAMES.BMP_BTR]: 20348,
        [NAMES.ARTA]: 21975,
        [NAMES.PPO]: 1046,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22383,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 33995
    },
    "Jan 16 2025": {
        [NAMES.TROOPS]: 814150,
        [NAMES.TANKS]: 9791,
        [NAMES.BMP_BTR]: 20368,
        [NAMES.ARTA]: 22015,
        [NAMES.PPO]: 1046,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22503,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34132
    },
    "Jan 17 2025": {
        [NAMES.TROOPS]: 815820,
        [NAMES.TANKS]: 9803,
        [NAMES.BMP_BTR]: 20381,
        [NAMES.ARTA]: 22019,
        [NAMES.PPO]: 1046,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22566,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34256
    },
    "Jan 18 2025": {
        [NAMES.TROOPS]: 817160,
        [NAMES.TANKS]: 9803,
        [NAMES.BMP_BTR]: 20394,
        [NAMES.ARTA]: 22040,
        [NAMES.PPO]: 1046,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22579,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34325
    },
    "Jan 19 2025": {
        [NAMES.TROOPS]: 818740,
        [NAMES.TANKS]: 9811,
        [NAMES.BMP_BTR]: 20412,
        [NAMES.ARTA]: 22055,
        [NAMES.PPO]: 1046,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22615,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34401
    },
    "Jan 20 2025": {
        [NAMES.TROOPS]: 820430,
        [NAMES.TANKS]: 9821,
        [NAMES.BMP_BTR]: 20454,
        [NAMES.ARTA]: 22074,
        [NAMES.PPO]: 1049,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22768,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34488
    },
    "Jan 21 2025": {
        [NAMES.TROOPS]: 822030,
        [NAMES.TANKS]: 9833,
        [NAMES.BMP_BTR]: 20477,
        [NAMES.ARTA]: 22134,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 22898,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34682
    },
    "Jan 22 2025": {
        [NAMES.TROOPS]: 823980,
        [NAMES.TANKS]: 9844,
        [NAMES.BMP_BTR]: 20485,
        [NAMES.ARTA]: 22194,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 23039,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34837
    },
    "Jan 23 2025": {
        [NAMES.TROOPS]: 825320,
        [NAMES.TANKS]: 9850,
        [NAMES.BMP_BTR]: 20497,
        [NAMES.ARTA]: 22256,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 23111,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34905
    },
    "Jan 24 2025": {
        [NAMES.TROOPS]: 826820,
        [NAMES.TANKS]: 9852,
        [NAMES.BMP_BTR]: 20508,
        [NAMES.ARTA]: 22295,
        [NAMES.PPO]: 1050,
        [NAMES.JETS]: 369,
        [NAMES.HELI]: 331,
        [NAMES.UAV]: 23162,
        [NAMES.SHIPS]: 29,
        [NAMES.AUTO]: 34992
    }
};
